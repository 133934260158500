/* eslint-disable no-template-curly-in-string */
const typeTemplate = '${label} não é um(a) ${type} válido(a)'
const ptBR = {
  locale: 'pt',
  common: {
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    loading: 'Carregando...',
    close: 'Fechar',
  },
  Calendar: {
    markItems: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
    renderYearAndMonth: (year, month) => `${year}/${month}`,
  },
  Cascader: {
    placeholder: 'Selecione',
  },
  Dialog: {
    ok: 'Confirmar',
  },
  DatePicker: {
    tillNow: 'Agora',
  },
  ErrorBlock: {
    default: {
      title: 'Oops, algo deu errado',
      description: 'Por favor, espere um minuto e tente novamente',
    },
    busy: {
      title: 'Oops, algo deu errado',
      description: 'Tente atualizar a página',
    },
    disconnected: {
      title: 'Rede ocupada.',
      description: 'Tente atualizar a página',
    },
    empty: {
      title: 'Hum, não foi possível encontrar isso...',
      description: 'Want to try a new search?',
    },
  },
  Form: {
    required: 'Obrigatório',
    optional: 'Opcional',
    defaultValidateMessages: {
      default: 'Erro de validação de campo para ${label}',
      required: 'Por favor, insira ${label}',
      enum: '${label} Deve ser um dos [${enum}]',
      whitespace: '${label} Não pode ser um caractere em branco',
      date: {
        format: 'O formato de data ${label} é inválido',
        parse: '${label} não pode ser convertido para uma data',
        invalid: '${label} é uma data inválida',
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
      },
      string: {
        len: '${label} deve ter ${len} caracteres.',
        min: '${label} deve ter pelo menos ${min} caracteres.',
        max: '${label} deve ter no máximo ${max} caracteres.',
        range: '${label} deve ter entre ${min} e ${max} caracteres.',
      },
      number: {
        len: '${label} deve ser igual a ${len}',
        min: '${label} deve ser no mínimo ${min}',
        max: '${label} deve ser no máximo ${max}',
        range: '${label} deve estar entre ${min}-${max}',
      },
      array: {
        len: 'Deve conter ${len} ${label}',
        min: 'Deve conter pelo menos ${min} ${label}',
        max: 'Deve conter no máximo ${max} ${label}',
        range: 'A quantidade de ${label} deve estar entre ${min}-${max}',
      },
      pattern: {
        mismatch: '${label} não corresponde ao padrão ${pattern}',
      },
    },
  },
  ImageUploader: {
    uploading: 'Carregando...',
    upload: 'Carregar',
  },
  InfiniteScroll: {
    noMore: 'Não há mais',
    failedToLoad: 'Falha ao carregar',
    retry: 'Tentar novamente',
  },
  Input: {
    clear: 'Limpar',
  },
  Mask: {
    name: 'Máscara',
  },
  Modal: {
    ok: 'OK',
  },
  PasscodeInput: {
    name: 'Entrada de Código de Acesso',
  },
  PullToRefresh: {
    pulling: 'Deslize para baixo para atualizar',
    canRelease: 'Solte para atualizar imediatamente',
    complete: 'Atualização bem-sucedida',
  },
  SearchBar: {
    name: 'Barra de Pesquisa',
  },
  Slider: {
    name: 'Controle de Escolha',
  },
  Stepper: {
    decrease: 'diminuir',
    increase: 'aumentar',
  },
  Switch: {
    name: 'Interruptor',
  },
}
export default ptBR
