const ptBR = {
  Welcome: 'Bem-vindo',
  Players: 'Jogadores',
  'Player Info': 'Informações do Jogador',
  total: 'total',
  Reward: 'Recompensa',
  Profile: 'Perfil',
  'Total Players': 'Total de Jogadores',
  'Total Recharge': 'Total de Recargas',
  'Total Withdrawal': 'Total de Saques',
  'My Profile': 'Meu Perfil',
  'My Team': 'Meu Time',
  'My Wallet': 'Minha Carteira',
  'My Rewards': 'Minhas Recompensas',
  'My Transactions': 'Minhas Transações',
  'My Promotions': 'Minhas Promoções',
  'My Games': 'Meus Jogos',
  'Deviation Rate': 'Taxa de Desvio',
  'Daily logs': 'Logs diários',
  Name: 'Nome',
  Phone: 'Telefone',
  Password: 'Senha',
  'Download App Link': 'Link para Baixar o App',
  'Sign in': 'Entrar',
  'Sign out': 'Sair',
  Or: 'Ou',
  'Sign up': 'Cadastrar',
  'Sign in successful': 'Entrada bem sucedida',
  'Sign up successful': 'Cadastro bem sucedido',
  'Input your name': 'Digite seu nome',
  'Input your phone number': 'Digite seu número de telefone',
  'Input your password': 'Digite sua senha',
  deviation: 'desvio',
  recharge: 'recarga',
  withdrawal: 'saque',
  Copy: 'Copiar',
  'App Link': 'Link do App',
  'Invite Agent Link': 'Link para Convidar Agentes',
  'Sharing the url to invite friends be an agent': 'Compartilhe o link para convidar amigos a serem agentes',
  'Sharing the url to invite friends to play games': 'Compartilhe o link para convidar amigos a jogar',
  Level: 'Nível',
  Ratio: 'Taxa',
  'Last 10 recharge orders': 'Últimos 10 pedidos de recarga',
  'Last 10 withdraw orders': 'Últimos 10 pedidos de saque',
}

export default ptBR
