import { List, Space, Empty, Tag } from 'antd-mobile'
import { moneyFormater, ll } from '@/utils/helper'
import { useModel, useRequest, history } from 'umi'
import styles from './style.less'
import moment from 'moment'

export default ({ id: userId, type = 'recharge' }) => {
  const { initialState: { sys: { dict } } } = useModel('@@initialState')
  // const { userId } = data
  const title = ll(`Last 10 ${type} orders`)

  const api = `/api/players/${userId}/orders?type=${type}`
  const { data: { data = [] } = {}, loading } = useRequest(api)

  return (
    <List header={title} loading={loading} className={styles.orderList}>
      {
      data?.length
        ? data.map(row => {
          return (
          <List.Item
            // style={{ '--padding-left': '42px' }}
            key={row.orderId}
            prefix=" "
            description={moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          >
            <span className={type === 'recharge' ? 'green' : 'red'}> {`${moneyFormater(row.amount)}`}</span>
          </List.Item>
          )
        })
        : <Empty />
      }
    </List>
  )
}
