import OrderList from '@/components/OrderList'
import PlayerInfo from '@/components/PlayerInfo'
// import RewardList from '@/components/RewardList'
// import StatsCard from '@/components/StatsCard'
import { ll } from '@/utils/helper'
import { NavBar, Skeleton } from 'antd-mobile'
import { history, useParams, useRequest } from 'umi'

const Page = (props) => {
  // const { initialState } = useModel('@@initialState')
  const { id } = useParams()
  const api = `/api/player/${id}`
  const { data = {}, loading } = useRequest(api, {
    ready: id,
    refreshDeps: [id],
    cacheKey: api,
  })

  const handleBack = () => {
    history.replace('/promo/players')
  }

  return (
    <>
    <NavBar onBack={handleBack} right={null}>{ll('Player Info')}</NavBar>
      {loading
        ? <Skeleton.Paragraph lineCount={5} animated />
        : <>
          <PlayerInfo id={id} data={data} />
          <OrderList id={id} data={data} type="recharge" />
          <OrderList id={id} data={data} type="withdraw" />
          {/* <RewardList id={id} data={data} /> */}
          </>}
    </>

  )
}

export default Page
