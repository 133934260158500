import { delay, ll } from '@/utils/helper'
import {
  Button, Form,
  Input, NavBar,
  Toast,
} from 'antd-mobile'
import React, { useRef } from 'react'
import { history, useModel, useLocation } from 'umi'

export default () => {
  // const location = useLocation()
  // console.log('🚀 ~ file: signup.js ~ line 12 ~ location', location)

  const formRef = useRef()
  const auth = useModel('auth')

  const onFinish = async (values) => {
    await auth.signup(values)
    Toast.show(ll('Sign up successful'))
    await delay(500)
    history.replace('/')
  }

  const onBack = () => {
    history.replace('/')
  }

  return (
    <>
      <NavBar back={ll('Sign in')} onBack={onBack}>{ll('Sign up')}</NavBar>
      <Form
        ref={formRef}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // layout='horizontal'
        footer={
          <Button block type="submit" color="primary">{ll('Sign up')}</Button>
        }
      >
        <Form.Item
          name="screenName" label={ll('Name')}
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder={ll('Input your name')} />
        </Form.Item>
        <Form.Item
          name="phone" label={ll('Phone')}
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder={ll('Input your phone number')} />
        </Form.Item>
        <Form.Item name="userPasswd" label={ll('Password')}>
          <Input type="password" placeholder={ll('Input your password')} />
        </Form.Item>

        {/* <Form.Item name='otp' label='OTP' extra={<a>Send</a>}>
          <Input placeholder='' placeholder='input OTP' />
        </Form.Item> */}

      </Form>
    </>
  )
}
