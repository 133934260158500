import Auth from '@/models/auth'
import { ConfigProvider, Toast } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import ptBR from '@/locales/antd/pt-BR'
import moment from 'moment'
import React from 'react'
import { history, request as fetch } from 'umi'
import { APP_LOCALS } from './global'

// 错误处理方案： 错误类型
enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}
// 统一转化数据格式
interface ResponseStructure {
  success: boolean
  data: any
  resData: any
  errorCode?: number
  errorMessage?: string
  showType?: ErrorShowType
}
/**
 * 异常处理程序
 */

const errorThrower = (res: ResponseStructure) => {
  const { success, data, errorCode, errorMessage, showType } = res
  if (!success) {
    const error: any = new Error(errorMessage)
    error.name = 'BizError'
    error.info = { errorCode, errorMessage, showType, data }
    throw error // 抛出自制的错误
  }
}
const errorHandler = (error, opts) => {
  if (opts?.skipErrorHandler) throw error

  const { name, info, response } = error
  // // 业务错误
  if (name === 'BizError') {
    const { errorMessage, errorCode, showType } = info

    if (errorCode === -2) {
      // 未登录 跳转登录
      // @TODO 考虑更合理的实现
      history.replace('/sign/signin')
      return
    }

    if (errorCode === 403) {
      // 无权限
      history.replace('/404')
      return
    }
    const content = errorMessage || 'Network error, Please check your network and try again'
    Toast.show({
      icon: 'fail',
      content,
      // position: 'top',
    })
    return
  }

  const content = `${error.message}, Please check your network and try again`
  Toast.show({
    content,
  })
  // throw error
  // }
}

const responseInterceptor = (response) => {
  // 拦截响应数据，进行个性化处理
  const { data = {} /* , status */ } = response
  let { msg, msgCode, resData, showType } = data
  // tmp处理
  if (msgCode === undefined && resData === undefined) {
    // response.data = data
    // return response
    msgCode = 0
    resData = data
  }

  // 统一转化接口返回值格式
  const res: ResponseStructure = {
    success: msgCode === 0,
    errorCode: msgCode,
    errorMessage: msg,
    showType,
    data: resData,
    resData,
  }
  response.data = res
  return response
}

const appendToken = (url, options) => {
  const token = Auth().getToken()
  if (token) {
    options.headers.Authorization = `Bearer ${token}`
  }
  options.headers.__t = Date.now()
  return { url, options }
}

export const request = {
  timeout: 10000,
  credentials: 'include', // 默认请求是否带上cookie
  errorConfig: {
    errorThrower,
    errorHandler,
  },
  requestInterceptors: [appendToken],
  responseInterceptors: [responseInterceptor],
  refreshOnWindowFocus: true,
}

export async function getInitialState () {
  const { resData: sysConfig } = await fetch('/api/sys/init') // 初始化系统配置

  const result = {
    time: moment().unix(),
    sys: sysConfig,
  }
  const { pathname } = history.location
  // 如果是登录页面，不执行
  if (['/sign/signin', '/sign/signup'].includes(pathname)) {
    return result
  }
  const auth = Auth()
  const currentUser = await auth.fetchCurrent()
  result.currentUser = currentUser
  // result.currentUser = auth.parseToken()
  result.token = auth.getToken()
  return result
}

const locale = APP_LOCALS === 'brazil' ? ptBR : enUS
export function rootContainer (container) {
  return React.createElement(ConfigProvider, { locale }, container)
}
