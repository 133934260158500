import AgentInfo from '@/components/AgentInfo'
import DailyRewardList from '@/components/DailyRewardList'
import StatsCard from '@/components/StatsCard'
import { NavBar, Skeleton } from 'antd-mobile'
import React from 'react'
import { history, useModel, useRequest } from 'umi'

const Page = (props) => {
  const { initialState: { sys: { dict } } } = useModel('@@initialState')
  const { match } = props
  const { id } = match.params

  const api = `/api/agent/${id}`
  const { data = {}, loading } = useRequest(api)

  const handleBack = () => {
    history.go(-1)
  }
  const handleClick = () => {
  }

  return (
    <>
    <NavBar
      onBack={handleBack} right={null}
    >Agent Info
    </NavBar>
      {loading
        ? <Skeleton.Paragraph lineCount={5} animated />
        : <>
          <AgentInfo id={id} data={data} />
          <StatsCard id={id} data={data} />
          <DailyRewardList id={id} data={data} />
          </>}
    </>

  )
}

export default Page
