import { APP_LOCALE, APP_LOCALS } from '@/global'
import { Tag } from 'antd-mobile'
import { history } from 'umi'
export const delay = (time) =>
  new Promise((resolve) => {
    setTimeout(resolve, time)
  })

export const QS = {
  stringify: (obj) => {
    return new URLSearchParams(obj).toString()
  },
  parse: (str, map = true) => {
    const o = new URLSearchParams(str)
    if (!map) {
      return o
    }
    const ret = {}
    for (const [key, value] of o.entries()) {
      ret[key] = value
    }
    return ret
  },
}

export const copyText = async (entryText) => {
  // const result = await navigator.permissions.query({ name: 'clipboard-write' })
  // if (result.state == "granted" || result.state == "prompt") {
  //   alert("Write access ranted!");
  // }
  await navigator.clipboard.writeText(entryText)
}

export const redirectToLogin = (route = '/sign/signin') => {
  const { location: { pathname } } = history
  if (pathname === route) {
    return
  }
  const queryString = QS.stringify({
    redirect: window.location.href,
  })
  // history.replace(`${route}?${queryString}`)
  window.location.href = `${window.location.origin}${route}?${queryString}`
}
export const currencyCode = (APP_LOCALS === 'brazil' ? 'BRL' : 'INR')
// export const locale = APP_LOCALS === 'brazil' ? 'pt-BR' : 'en-US'
export const moneyFormater = (val = 0, prefix = true, digit = 2, currency = currencyCode) => {
  const options = {
    // style: 'currency',
    // currency: 'INR',
    useGrouping: false,
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  }
  if (prefix) {
    options.style = 'currency'
    options.currency = currency
  }
  // return `${prefix} ${(val * 1).toLocaleString('en-US', options)}`
  return (val * 1).toLocaleString(APP_LOCALE, options)
}

export const statusRender = (
  statusEnum,
  field = 'status',
) => {
  return (text, row = {}) => {
    const key = row[field]
    const v = statusEnum[key] || {}
    const color = (v.status || v.color || '').toLowerCase()
    return <Tag color={color}>{v.text}</Tag>
  }
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
export function mergeLocale (base, patch) {
  function merge (a, b) {
    if (typeof a !== 'object' || typeof b !== 'object' || Array.isArray(a) || Array.isArray(b)) {
      return b !== undefined ? b : a
    }
    const result = {}
    for (const key in a) {
      // eslint-disable-next-line no-prototype-builtins
      if (a.hasOwnProperty(key)) {
        result[key] = merge(a[key], b[key])
      }
    }
    return result
  }
  return merge(base, patch)
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const localeMap = require(`@/locales/${APP_LOCALE}.js`).default
export const lang = (key, params = {}) => {
  console.log('🚀 ~ file: helper.js:78 ~ localeMap:', localeMap)
  return localeMap[key] || key
}
export const ll = lang

export const intl = {
  dateFormat: new Intl.DateTimeFormat(APP_LOCALE),
  numberFormat: new Intl.NumberFormat(APP_LOCALE),
}
