import { List, Empty, Skeleton } from 'antd-mobile'
import { moneyFormater } from '@/utils/helper'
import moment from 'moment'
import React from 'react'
import { useRequest } from 'umi'

const dataset = []
for (let i = 0; i < 10; i++) {
  const row = {
    key: i,
    daily: moment().subtract(i, 'd'),
    money: 10000,
    description: 'level up',
  }
  dataset.push(row)
}

export default ({ id }) => {
  if (!id) return null
  const { data, loading } = useRequest(`/api/agent/${id}/    `)
  return (
    <>
      <List header="Daily Reward">
        {!dataset.length
          ? <Empty description="" />
          : dataset.map(row => (
            <List.Item
              key={row.id}
              extra={row.daily.format('YYYY-MM-DD')}
              description={row.description}
            >
              <span className="red"> {`+${moneyFormater(row.money)}`}</span>
            </List.Item>
          ))}
      </List>
    </>
  )
}
