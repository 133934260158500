import { moneyFormater, ll } from '@/utils/helper'
import { Card, Skeleton, Grid } from 'antd-mobile'
import React from 'react'
import { useRequest } from 'umi'
import style from './style.less'

export default ({ data }) => {
  // const api = '/api/user/current'
  // const { data, loading } = useRequest(
  //   api,
  //   {
  //     cacheKey: api,
  //   },
  // )

  return (
    <>
    <Grid columns={2} gap={4} className={style.statBlock}>
      <Grid.Item>
        {/* <Card title="AGENTS">
        {data?.agentCount || '-'}
        </Card> */}
      </Grid.Item>
      <Grid.Item span={2}>
        <Card title="PLAYERS">
          {data?.playerCount || '-'}
        </Card>
      </Grid.Item>
      <Grid.Item>
        <Card title={ll('TOTAL REWARDS')}>
        {moneyFormater(data?.totalAmount, true, 0)}
        </Card>
      </Grid.Item>
      <Grid.Item>
        <Card title={ll('WITHDREW REWARDS')}>
        {moneyFormater(data?.withdrawAmount, true, 0)}
        </Card>
      </Grid.Item>
    </Grid>
    </>
  )
}
