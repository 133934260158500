
import { delay, moneyFormater } from '@/utils/helper'
import { Empty, List, NavBar, PullToRefresh, Skeleton, Toast, Space, Tag, InfiniteScroll } from 'antd-mobile'
import { useEffect, useState } from 'react'
import { useRequest, history, useModel } from 'umi'
import moment from 'moment'

const refreshStatusEnum = {
  pulling: 'pull it down to refresh',
  canRelease: 'release to refresh',
  refreshing: 'loading...',
  complete: 'done',
}

const Page = () => {
  // const [data, setData] = useState()
  const { initialState: { sys: { dict } } } = useModel('@@initialState')
  const { agentStatusEnum } = dict
  const [hasMore, setHasMore] = useState(true)

  const api = '/api/agents'
  const { data = {}, loading, run } = useRequest(
    api,
    // async () => { await request(api, { method: 'GET' }) },
    {
      manual: true,
      cacheKey: api,
      // onSuccess: (res, params) => {
      //   setData(res.data)
      // },
    },
  )

  useEffect(() => {
    run()
  }, [])

  const handleClick = (row) => {
    const { id, screenName } = row
    // Toast.show(`Click on item ${id}`)
    history.push(`/promo/agents/${id}`)
  }

  const { data: dataset, total = 0 } = data || {}
  const right = (
    total ? <div>total: {total}</div> : null
  )

  return (
    <>
      <NavBar back={null} right={right}>Agents</NavBar>
      <PullToRefresh
        renderText={status => <div>{refreshStatusEnum[status]}</div>}
        onRefresh={async () => {
          await delay(1000)
          run()
        }}
      >
        <List>
          {loading && !dataset && <Skeleton.Paragraph lineCount={10} animated />}
          {dataset?.map(row => (
            <List.Item
              key={row.id}
              extra={<>
              <Tag color={agentStatusEnum[row.status].status}>{agentStatusEnum[row.status].text}</Tag>
                     </>}
              // prefix={`lv: ${row.level}`}
              // extra={`lv: ${row.level}`}
              // extra={`${moneyFormater(row.withdrawAmount)} / ${moneyFormater(row.totalAmount)}`}
              description={moment(row.regAt).format('YYYY-MM-DD HH:mm:ss')}
              onClick={() => { handleClick(row) }}
            >
                <Space
                  justify="start" align="start"
                  style={{ '--gap': '24px' }}
                  block
                >
                  <div>{row.screenName}</div>
                  <div>{row.phone}</div>
                </Space>
            </List.Item>
          ))}
        </List>
        {/* <InfiniteScroll loadMore={loadMore} hasMore={hasMore} /> */}
        {(dataset && dataset.length === 0) &&
        <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          description="not promotion record"
        />}
      </PullToRefresh>
    </>
  )
}

export default Page
