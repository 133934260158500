
import { history } from 'umi'

export default () => {
  // console.log('🚀 ~ file: index.js ~ line 3 ~ history', history)
  // 取邀请码, 写入 localStorage

  // const inviteCode = history.location.search.split('=')[1]
  // 跳转到 注册页面

  setTimeout(() => {
    history.replace('/promo/players')
  }, 1)
  return <></>
}
