// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"name":"sign","path":"/sign","id":"1"},"2":{"title":"Sign In","path":"/sign/signin","parentId":"1","id":"2"},"3":{"title":"Sign Up","path":"/sign/signup","parentId":"1","id":"3"},"4":{"path":"/","id":"4"},"5":{"title":"Promo System","path":"/","parentId":"4","id":"5"},"6":{"title":"My Agents","path":"/promo/agents","parentId":"4","id":"6"},"7":{"title":"Agent Detail","path":"/promo/agents/:id","parentId":"4","id":"7"},"8":{"title":"My Players","path":"/promo/players","parentId":"4","id":"8"},"9":{"title":"Player Detail","path":"/promo/players/:id","parentId":"4","id":"9"},"10":{"title":"My Rewards","path":"/reward","parentId":"4","id":"10"},"11":{"title":"My Profile","path":"/profile","parentId":"4","id":"11"},"12":{"path":"*","parentId":"4","id":"12"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "layouts__SignLayout" */'@/layouts/SignLayout.js')),
'2': React.lazy(() => import(/* webpackChunkName: "p__sign__signin" */'@/pages/sign/signin.js')),
'3': React.lazy(() => import(/* webpackChunkName: "p__sign__signup" */'@/pages/sign/signup.js')),
'4': React.lazy(() => import(/* webpackChunkName: "layouts__TabBarLayout" */'@/layouts/TabBarLayout.jsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.js')),
'6': React.lazy(() => import(/* webpackChunkName: "p__promo__agents__index" */'@/pages/promo/agents/index.jsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__promo__agents__$id" */'@/pages/promo/agents/$id.jsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__promo__players__index" */'@/pages/promo/players/index.jsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__promo__players__$id" */'@/pages/promo/players/$id.jsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__reward__index" */'@/pages/reward/index.js')),
'11': React.lazy(() => import(/* webpackChunkName: "p__profile__index" */'@/pages/profile/index.js')),
'12': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.js')),
},
  };
}
