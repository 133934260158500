import DailyLogList from '@/components/DailyLogList'
import { ll } from '@/utils/helper'
import { NavBar } from 'antd-mobile'
import { useRequest } from 'umi'
export default () => {
  // const right = (
  //   <div>
  //     {!editing
  //       ? <Button size="small" shape="rounded" onClick={() => { setEditing(true) }}><FillinOutline /> Edit</Button>
  //       : <Button size="small" color="primary" shape="rounded" onClick={onSubmit} loading={submitting}><FileOutline /> Save</Button>}
  //   </div>
  // )

  // const right = (
  //   <div>
  //     <Button size="small" shape="rounded" onClick={onSignout}>Sign out</Button>
  //   </div>
  // )
  const api = '/api/user/current?stats=1'
  const { data = {}, loading } = useRequest(
    api,
    {
      formatResult: (res) => res.resData,
      cacheKey: api,
    },
  )
  const right = null
  const { id } = data
  return (
    <>
    <NavBar back={null} right={right}>{ll('My Rewards')}</NavBar>
    {/* <StatsCard data={data} /> */}
    <DailyLogList id={id} />
    </>
  )
}
