import { APP_NAME } from '@/global'
import { copyText, delay, ll, QS } from '@/utils/helper'

import { Button, Form, Input, NavBar, Skeleton, TextArea, Toast } from 'antd-mobile'
import React, { useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { history, useModel, useRequest } from 'umi'

export default () => {
  const formRef = useRef()
  const [editing, setEditing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const auth = useModel('auth')
  const { initialState: { sys, currentUser, time } } = useModel('@@initialState')
  // console.log('🚀 ~ file: index.js ~ line 14 ~ currentUser', currentUser)

  const api = '/api/user/current'
  const { data = {}, loading } = useRequest(
    api,
    {
      cacheKey: api,
    },
  )

  const onFinish = async (values) => {
    setSubmitting(true)
    await delay(1000)
    setSubmitting(false)
    setEditing(false)

    // Dialog.alert({
    //   content: JSON.stringify(values),
    // })
  }

  const onFinishFailed = (errorInfo) => {
    // console.log('🚀 ~ file: index.js ~ line 28 ~ onFinishFailed ~ errorInfo', errorInfo)
  }

  const onSubmit = () => {
    formRef.current.submit()
  }

  const onCopy = (field) => () => {
    const text = formRef.current.getFieldValue(field)
    copyText(text)
    Toast.show('Copied!')
  }

  // const initialValues = {
  //   screenName: 'test user',
  //   phone: '9999999',
  //   promoCode: 'EU2FDI',
  // }
  const initialValues = data || {}
  const params = {}
  if (APP_NAME === 'trucosli') {
    params.appid = sys.channelId
  }
  params.code = initialValues.promoCode
  initialValues.promoLink = `${sys.promoBaseURL}?code=${initialValues.promoCode}`
  initialValues.downloadLink = `${sys.downloadBaseURL}?${QS.stringify(params)}`

  // const right = (
  //   <div>
  //     {!editing
  //       ? <Button size="small" shape="rounded" onClick={() => { setEditing(true) }}><FillinOutline /> Edit</Button>
  //       : <Button size="small" color="primary" shape="rounded" onClick={onSubmit} loading={submitting}><FileOutline /> Save</Button>}
  //   </div>
  // )

  const onSignout = async () => {
    await auth.signout()
    history.replace('/sign/signin')
  }
  const right = (
    <div>
      <Button size="small" shape="rounded" onClick={onSignout}>{ll('Sign out')}</Button>
    </div>
  )

  const form = (
    <Form
      ref={formRef}
      // layout='horizontal'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
    >
      <Form.Item
        name="screenName"
        label={ll('Name')}
      // rules={[{ required: true, message: '' }]}
      >
        <Input placeholder={ll('Name')} readOnly={!editing} />
      </Form.Item>

      <Form.Item name="phone" label={ll('Phone')}>
        <Input placeholder={ll('Phone')} readOnly={!editing} />
      </Form.Item>

      <Form.Item name="pass" label={ll('Password')} hidden={!editing}>
        <Input placeholder={ll('Password')} />
      </Form.Item>

      <Form.Item
        hidden={true || currentUser.level < 2}
        // name="promoLink"
        label={ll('Invite Agent Link')}
        help={ll('Sharing the url to invite friends be an agent')}
        extra={
          <CopyToClipboard text={initialValues.promoLink}>
            <Button color="primary" size="small" onClick1={onCopy('promoLink')}>Copy</Button>
          </CopyToClipboard>
        }
      >
        <TextArea readOnly />
      </Form.Item>

      <Form.Item
        name="downloadLink"
        label={(APP_NAME === 'trucosli') ? ll('App Link') : ll('Download App Link')}
        help={ll('Sharing the url to invite friends to play games')}
        extra={
          <CopyToClipboard text={initialValues.downloadLink}>
            <Button color="primary" size="small" onClick1={onCopy('downloadLink')}>{ll('Copy')}</Button>
          </CopyToClipboard>
        }
      >
        <TextArea readOnly />
      </Form.Item>

      <Form.Item name="level" label={ll('Level')} hidden>
        <Input readOnly />
      </Form.Item>
      <Form.Item name="promoRatio" label={ll('Ratio')} hidden>
        <Input readOnly />
        {/* {`${initialValues?.promoRatio}%`} */}
      </Form.Item>
    </Form>
  )

  return (
    <>
      <NavBar back={null} right={right}>{ll('My Profile')}</NavBar>
      {
        loading
          ? <Skeleton.Paragraph lineCount={5} animated />
          : form
      }
    </>

  )
}
