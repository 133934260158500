// import BlankLayout from './BlankLayout'
import { ll } from '@/utils/helper'
import { TabBar } from 'antd-mobile'
import {
  CalculatorOutline, TeamOutline, UserOutline,
} from 'antd-mobile-icons'
import { history, Outlet, useLocation } from 'umi'

export default function (props) {
  const { pathname } = useLocation()
  // const location = useLocation()
  // if (pathname.indexOf('/sign/sign') !== -1) {
  //   return (
  //     <BlankLayout>{props.children}</BlankLayout>
  //   )
  // }
  // const { initialState: { currentUser } = {} } = useModel('@@initialState')

  // const [prevProps, setPrevProps] = useState(props)
  // useEffect(() => {
  //   setPrevProps(props)
  //   if (props.location !== prevProps.location) {
  //     window.scrollTo(0, 0)
  //   }
  // }, [])

  const setRouteActive = (value) => {
    history.push(value)
  }

  const tabs = [
    // {
    //   key: '/promo/agents',
    //   title: 'Agents',
    //   icon: <AppOutline />,
    //   // badge: Badge.dot,
    // },
    {
      key: '/promo/players',
      title: 'Players',
      icon: <TeamOutline />,
      // icon: (active) => active ? <MessageFill /> : <MessageOutline />,
      // badge: '99+',
    },
    {
      key: '/reward',
      title: 'Reward',
      // icon: <CollectMoneyOutline />,
      icon: <CalculatorOutline />,
      // badge: '5',
    },
    {
      key: '/profile',
      title: 'Profile',
      icon: <UserOutline />,
    },
  ]

  const activeKey = tabs.find(t => pathname.indexOf(t.key) > -1)?.key

  return (
    <>
      <div className="container">
        <div className="header" />

        <Outlet />

        <div className="footer">
        <TabBar activeKey={activeKey} onChange={value => setRouteActive(value)}>
          {tabs.map(item => (
            <TabBar.Item
              key={item.key}
              icon={item.icon}
              title={ll(item.title)}
              badge={item.badge}
            />
          ))}
        </TabBar>
        </div>
      </div>
    </>
  )
}
