import { moneyFormater, ll } from '@/utils/helper'
import { AutoCenter, DotLoading, Empty, Grid, List } from 'antd-mobile'
import moment from 'moment'
import React from 'react'
import { useRequest } from 'umi'

import styles from './style.less'

export default ({ id }) => {
  const { data: { data } = {}, loading } = useRequest(`/api/agent/${id}/daily_log`, {
    ready: id,
    refreshDeps: [id],
  })
  return (
    <>
      <List
        header={ll('Daily logs')}
        className={styles.dailyLogList}
      >
        {loading && <AutoCenter><DotLoading /></AutoCenter>}

        {!data?.length
          ? <Empty size="large" description="" />
          : data.map(item => (
            <List.Item
              key={item.id}
              title={moment(item.daily).format('L')}
              // description={item.daily.format('YYYY-MM-DD')}
              // description={item.description}
            >

              <Grid columns={3} gap={8}>
                <Grid.Item>
                  <label>{ll('recharge')}</label>
                  <span className="green">{moneyFormater(item.recharge, true, 0)}</span>
                </Grid.Item>
                <Grid.Item>
                  <label>{ll('withdrawal')}</label>
                  <span className="red">{moneyFormater(item.withdraw, true, 0)}</span>
                </Grid.Item>
                <Grid.Item>
                  <label>{ll('deviation')}</label>
                  <span>{moneyFormater(item.recharge - item.withdraw, true, 0)}</span>
                </Grid.Item>
              </Grid>
            </List.Item>
          ))}
      </List>
    </>
  )
}
