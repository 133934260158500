import {
  Button, Form,
  Input, NavBar, Space, Toast,
} from 'antd-mobile'
import React, { useRef } from 'react'
import { history, useModel } from 'umi'

import { delay, ll } from '@/utils/helper'

export default () => {
  const formRef = useRef()
  const auth = useModel('auth')

  const onFinish = async (values) => {
    await auth.signin(values)
    Toast.show(ll('Sign in successful'))
    await delay(500)
    // history.replace('/')
    window.location.href = '/'
  }

  return (
    <>
      <NavBar back={null}>{ll('Welcome')}</NavBar>
      <Form
        ref={formRef}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // layout='horizontal'
        footer={
          <>
          <Button block type="submit" color="primary">{ll('Sign in')}</Button>
          <Space style={{ float: 'right', marginTop: 20 }}>
          <span>{ll('Or')}</span>
          <a onClick={() => { history.push('/sign/signup') }}>{ll('Sign up')}</a>
          </Space>
          </>
        }
      >
        <Form.Item name="phone" label={ll('Phone')}>
          <Input placeholder={ll('Input your phone number')} />
        </Form.Item>
        <Form.Item name="userPasswd" label={ll('Password')}>
          <Input type="password" placeholder={ll('Input your password')} />
        </Form.Item>

        {/* <Form.Item name='otp' label='OTP' extra={<a>Send</a>}>
          <Input placeholder='' placeholder='input OTP' />
        </Form.Item> */}

      </Form>
    </>
  )
}
