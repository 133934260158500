/**
 * 处理全局配置
 */
import { Toast } from 'antd-mobile'
import moment from 'moment'
import 'moment-timezone'
import './global.less'

Toast.config({ duration: 3000 })

let env = 'local'
const { hostname } = window.location
if (hostname === 'localhost' || hostname.indexOf('1') === 0) {
  env = 'local'
} else {
  env = hostname.split('.')[1] || 'local'
}
const APP_NAME = env
const APP_LOCALS = ['local', 'cdn007', 'trucosli'].includes(APP_NAME) ? 'brazil' : 'india'
const APP_LOCALE = APP_LOCALS === 'brazil' ? 'pt-BR' : 'en-IN'
const EXCHANGE_RATE = APP_LOCALS === 'brazil' ? 5.5 : 77.5
if (APP_LOCALS === 'brazil') {
  moment.locale('es')
  moment.tz.setDefault('America/Sao_Paulo')
} else {
  moment.tz.setDefault('Asia/Kolkata') // 加尔各答时区
}

export {
  APP_NAME,
  APP_LOCALS,
  APP_LOCALE,
  EXCHANGE_RATE,
}
console.log('BUILD TIME:', BUILD_TIME)
