
import { delay, ll, moneyFormater } from '@/utils/helper'
import { Empty, Grid, InfiniteScroll, List, NavBar, PullToRefresh, Space } from 'antd-mobile'
import moment from 'moment'
import { useState } from 'react'
import { history, request, useRequest } from 'umi'
import styles from './styles.less'
// const statusEnum = {
//   pulling: 'pull it down to refresh',
//   canRelease: 'release to refresh',
//   refreshing: 'loading...',
//   complete: 'done',
// }

// const item = {
//   avatar:
//     'https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
//   screenName: 'Novalee',
//   phone: '111123123213',
//   regAt: '2022-07-11 16:45:15',
//   diff: 1000000,
//   income: 100.54,
//   recharge: 13000000.54,
//   withdraw: 1000000.54,
//   description: 'Deserunt dolor ea eaque eos',
// }

// const dataset = Array(20).fill(item)

export default function Page () {
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const api = '/api/promo/players'

  const loadMore = async () => {
    const offset = dataSource?.length
    const { resData: { data, total } } = await request(api, { params: { offset } })
    // await delay(500)
    const newDs = [...dataSource || [], ...data]
    setDataSource(newDs)
    setTotal(total)

    setHasMore(newDs.length < total)
  }

  const { loading, run } = useRequest(
    loadMore,
    {
      manual: true,
      loadingDelay: 300,
      cacheKey: api,
    },
  )

  const handleClick = (row) => {
    const { userId: id } = row
    history.push(`/promo/players/${id}`)
  }

  const right = total ? <div>total: {total}</div> : null

  return (
    <>
      <NavBar back={null} right={right}>{ll('Players')}</NavBar>
      <PullToRefresh
        // renderText={status => <div>{statusEnum[status]}</div>}
        onRefresh={async () => {
          setDataSource([])
          await delay(200)
          run(0)
        }}
      >
        <List className={styles.playerList}>
          {/* {loading && !dataset && <Skeleton.Paragraph lineCount={10} animated />} */}
          {dataSource?.map((item, i) => (
            <List.Item
              key={i}
              onClick={() => { handleClick(item) }}
              // title={item.screenName}
              // title={moment(item.regAt).format('YYYY-MM-DD HH:mm:ss')}
              title={moment(item.regAt).format('L LTS')}
              // prefix={
              //   <Image
              //     src={item.avatar}
              //     style={{ borderRadius: 20 }}
              //     fit="cover"
              //     width={42}
              //     height={42}
              //   />
              // }
              // extra={
              //   <span className="red">{moneyFormater(item.income)}</span>
              // }
              description={
                <Grid columns={2} gap={4}>
                <Grid.Item>
                  <label>{ll('recharge')}:</label>
                  <span className="green">{moneyFormater(item.rechargeTotal)}</span>
                </Grid.Item>
                <Grid.Item>
                  <label>{ll('withdrawal')}:</label>
                  <span className="red">{moneyFormater(item.withdrawTotal)}</span>
                </Grid.Item>
                {/* <Grid.Item span={2}>
                  {moment(item.registeredAt).format('YYYY-MM-DD HH:mm:ss')}
                </Grid.Item> */}
                {/* <Grid.Item span={2}>
                  Last Login :{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </Grid.Item> */}
                </Grid>
              }
            >
                <Space style={{ '--gap': '24px' }}>
                  <span>
                  {item.screenName}
                  </span>
                  <span>
                  {item.phone}
                  </span>
                </Space>
            </List.Item>
          ))}
        </List>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        {(!loading && dataSource && dataSource.length === 0) &&
        <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          // description="not record"
        />}
      </PullToRefresh>
    </>
  )
}
