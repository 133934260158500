// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/codebuild/output/src119257113/src/promo-center/src/models/auth';
import model_2 from '/codebuild/output/src119257113/src/promo-center/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'auth', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
