import { ll, moneyFormater } from '@/utils/helper'
import { List } from 'antd-mobile'
import { history, useModel } from 'umi'
export default ({ data = {} }) => {
  const { initialState: { sys: { dict } } } = useModel('@@initialState')
  const { userId } = data
  const handleClickTotal = type => e => {
    history.push(`/promo/players/${userId}/orders?type=${type}`)
  }
  return (
    <List mode="card">
    <List.Item title={ll('Name')}>{data?.screenName}</List.Item>
    <List.Item title={ll('Phone')}> {data?.phone}</List.Item>
    <List.Item title={ll('Total Recharge')} onClick_={handleClickTotal('recharge')}>
      <span className="green">{moneyFormater(data?.rechargeTotal)}</span>
    </List.Item>
    <List.Item title={ll('Total Withdrawal')} onClick_={handleClickTotal('withdraw')}>
      <span className="red">{moneyFormater(data?.withdrawTotal)}</span>
    </List.Item>
    {/* <List.Item>
      <Space justify="between" block>\
        <span><label>Level:</label> {data?.level}</span>
        <span><label>Ratio:</label> {`${data?.promoRatio}%`}</span>
      </Space>
    </List.Item> */}
    </List>
  )
}
