import { useState, useCallback } from 'react'
import { request, useModel } from 'umi'

const AUTH_KEY = 'authy010101'
export default function useAuth () {
  // const [data, setData] = useState({})
  // const { initialState = {}, setInitialState } = useModel('@@initialState')

  const getToken = () => {
    return window.localStorage.getItem(AUTH_KEY)
  }

  const setToken = (token) => {
    window.localStorage.setItem(AUTH_KEY, token)
  }

  const parseToken = () => {
    const token = getToken()
    if (!token) {
      return null
    }
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return null
    }
  }

  const signin = async (params) => {
    const { resData: data } = await request('/api/user/signin', { data: params, method: 'POST' })
    setToken(data.token)
    return data
  }

  const signup = async (params) => {
    const res = await request('/api/user/signup', { data: params, method: 'POST' })
    const { resData: data } = res
    setToken(data.token)
    return data
  }

  const signout = async (params) => {
    setToken(null)
  }

  const fetchCurrent = async () => {
    const { resData } = await request('/api/user/current')
    return resData
  }

  return {
    signin,
    signup,
    signout,
    setToken,
    getToken,
    parseToken,
    fetchCurrent,
  }
}
