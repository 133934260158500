import { List, Space, Tag } from 'antd-mobile'
import { useModel } from 'umi'
import styles from './style.less'
export default ({ data = {} }) => {
  const { initialState: { sys: { dict } } } = useModel('@@initialState')
  const { agentStatusEnum } = dict

  const statusTag = data.id ? <Tag color={agentStatusEnum[data?.status].status}>{agentStatusEnum[data?.status].text}</Tag> : null
  return (
    <List className={styles.infoBlock}>
    <List.Item title="Name" extra={statusTag}>{data?.screenName}</List.Item>
    <List.Item title="Phone:"> {data?.phone}</List.Item>
    <List.Item>
      <Space justify="between" block>
        <span><label>Level:</label> {data?.level}</span>
        <span><label>Ratio:</label> {`${data?.promoRatio}%`}</span>
      </Space>
    </List.Item>
    </List>
  )
}
